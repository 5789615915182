<template>
  <div class="first-forum-detail">
    <br>
    <BTitle :text="info.title"></BTitle>
    <div class="first-forum-content center">
      <div class="live">
        <video class="video" controls autoplay :poster="`${info.baseUrl}poster.jpg`" :src="`${info.baseUrl}${selectVideo}.mp4`"></video>
      </div>
      <br>
      <DTitle v-if="info.list && info.list.length > 1" style="margin:0 8px;" text="分段播放" tip="按出场顺序排列"/>
      <br>
      <div v-if="info.list && info.list.length > 1" class="list-wrap">
        <div v-for="(item, index) in info.list" :key="index" class="item" :class="item[0] === selectVideo && 'cur'" @click="playThis(item[0])">
          <p><i class="el-icon-video-play" style="margin-right: 6px;" /><span>{{ item[0] }} - {{ item[2] }}</span></p>
          <p style="font-size: 12px;color: #888;padding-left: 22px;">{{ item[1] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thread: this.$route.query.thread,
      selectVideo: '',
      forumInfo: [
        {
          title: '“绿色·低碳”创新发展论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/“绿色·低碳”创新发展论坛/',
          list: [
            ['潘永水','丽水市松阳县委常委、副县长','致辞'],
            ['杨硕宇','百度智能交通业务浙江省总经理','致辞'],
            ['陈徐梅','交通运输部科学研究院城市交通与轨道交通研究中心总工程师','双碳背景下推进城市公共交通高质量发展有关政策、实践与展望'],
            ['杨晓光','同济大学 博导、教授','绿色交通与运输之智能化发展'],
            ['赵溢峰','绍兴市城市建设投资集团有限公司 副总经理','智行合一，网络大城市绍兴市自动驾驶产业发展展望'],
            ['洪顺利','浙江交通职业技术学院物联网专业带头人','交通运输碳排放智能感知与协同控制关键技术探讨'],
            ['李静林','新塘信通（浙江）科技有限公司首席科学家','基于B5G/6G智能机器网络的交通专网'],
            ['张海林','','百度智能交通解决方案助力国家']
          ],
        },
        {
          title: '“绿色·低碳”创新发展论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/“绿色·低碳”创新发展论坛/',
          list: [
            ['潘永水','丽水市松阳县委常委、副县长','致辞'],
            ['杨硕宇','百度智能交通业务浙江省总经理','致辞'],
            ['陈徐梅','交通运输部科学研究院城市交通与轨道交通研究中心总工程师','双碳背景下推进城市公共交通高质量发展有关政策、实践与展望'],
            ['杨晓光','同济大学 博导、教授','绿色交通与运输之智能化发展'],
            ['赵溢峰','绍兴市城市建设投资集团有限公司 副总经理','智行合一，网络大城市绍兴市自动驾驶产业发展展望'],
            ['洪顺利','浙江交通职业技术学院物联网专业带头人','交通运输碳排放智能感知与协同控制关键技术探讨'],
            ['李静林','新塘信通（浙江）科技有限公司首席科学家','基于B5G/6G智能机器网络的交通专网'],
            ['张海林','','百度智能交通解决方案助力国家']
          ],
        },
        {
          title: '智慧高速建设与发展论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/智慧高速建设与发展论坛/',
          list: [
            ['汪东杰','浙江省交通运输厅党组成员、副厅长','浙江省智慧高速公路建设探索与思考'],
            ['刘楠','国家智能交通系统工程技术研究中心研究员','智慧公路系统与服务运营效果评价技术探究'],
            ['马军','上海平可行智能科技有限公司设计研究中心主任','聚焦用户服务，有的放矢构建高速公路智慧形态'],
            ['莫靓','中共松阳县委书记','迎风而起 道路宽广——在第四届浙江国际智慧交通产业博览会智慧高速建设与发展论坛上的演讲'],
            ['杨文银','原交通运输部规划研究院院长','公路用户税变革及征收技术路径探讨'],
            ['李斌','交通运输部公路科学研究院副院长','基础设施数字化发展与思考'],
            ['张磊','阿里云副总裁、城市大脑首席科学','新数据、新技术、新模式驱动智慧公路产业发展']
          ],
        },
        {
          title: '海运与物流发展论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/海运与物流发展论坛/',
          list: [
            ['陶成波','浙江省海港集团、宁波舟山港集团党委副书记、董事、总经理','致辞'],
            ['陈斯嘉','GSBN行政总裁','区块链技术在国际航运数字化转型中的运营与展望'],
            ['耿雄飞','交通运输部水运科学研究院智能航运中心主任','船舶智能航行技术发展与实践'],
            ['何晓飞','飞步科技创始人CEO','人工智能在自动驾驶中的应用和实践'],
            ['刘彬','环世物流首席技术官CTO','传统货代物流企业的数字化再造与升级'],
            ['张律','联通数科物联网事业部可信供应链BU总经理','数字化赋能智慧供应链的探索与实践'],
            ['李凌','挪威船级社大中华区技术支持经理','数字化对航运业的保障和增能实践'],
            ['张婕姝','上海国际航运研究中心秘书长','智慧港口发展与应用'],
            ['廖远忠','上海舟翼机电设备有限公司创始人','船舶智能监控管理系统与平台'],
            ['常嘉路','中国经济信息社','中国船舶交易价格指数成果发布'],
            ['徐凯','中国经济信息社','全球智慧港航指数视角的长三角关键要素流动观察报告']
          ],
        },
        {
          title: '“云感交通·数领未来”论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/“云感交通·数领未来”论坛/',
          list: [
            ['洪方明','华为公司机器视觉军团总裁','致辞'],
            ['张勇','新奇点智能科技集团有限公司首席科学家','《长三角数字公路建设技术标准》发布'],
            ['曾尔阳','深圳市微筑科技有限公司联合创始人','站城融合、数据驱动”，铁路枢纽智慧化建设经验分享'],
            ['范翔宇','华海智汇技术有限公司港口行业总监','港口数字化转型实践分享'],
            ['张志龙','华为机场与轨道军团民航解决方案专家','构筑闵行数字底座 助力“四型机场”建设'],
            ['童玉龙','华为数据存储交通行业总监','创新数据基础设施，共筑智慧交通新发展']
          ],
        },
        {
          title: '轨道交通发展论坛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/轨道交通发展论坛/',
          list: [
            ['陆雪忠','上海电气轨道交通集团副总裁','综合&智能维保模式（C&IMS）的可行性和有效性分析'],
            ['帅斌','西南交通大学交通运输学院副院长','“四网融合”下都市圈轨道交通融合体系研究'],
            ['周宇冠','中铁第四勘察设计院副总工程师','关于都市圈轨道跨线运行的思考'],
            ['刘岭','中国通号研究设计院集团副总经理','面向四网融合的区域轨道交通协同运输与服务技术']
          ],
        },
        {
          title: '第三届综合交通创新创业大赛总决赛',
          baseUrl: 'https://itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/第三届综合交通创新创业大赛总决赛/',
          list: [
            ['主视频','','']
          ],
        },
      ]
    }
  },
  computed: {
    info() {
      return this.forumInfo[this.thread] || {}
    }
  },
  created() {
    this.selectVideo = this.info.list[0][0]
  },
  methods: {
    playThis(val) {
      this.selectVideo = val
    }
  },
}
</script>

<style lang="less" scoped>
.first-forum-content {
  min-height: calc(100vh - 565px);
  padding-bottom: 30px;
  .desc {
    color: #666;
    font-size: 16px;
    text-indent: 32px;
    line-height: 25px;
  }
  .live {
    width: 1000px;
    margin: 0 auto;
    .video {
      margin: 20px auto;
      width: 1000px;
      height: 564px;
      border-radius: 5px;
      background: #342DB0;
      box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
    }
  }
  .list-wrap {
    padding-left: 20px;
    .item {
      font-size: 16px;
      color: #333;
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px dashed #EEE;
    }
    .cur {
      color: #0769CC;
      font-weight: bolder;
      cursor: default;
    }
  }
}
</style>
